import React, { lazy, Suspense, useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

const BrokerAssistant = lazy(() => import("./components/BrokerAssistant"));
const Commercial = lazy(() => import("./components/Commercial"));
const Home = lazy(() => import("./components/Home"));
const Residential = lazy(() => import("./components/Residential"));
const Thankyou = lazy(() => import("./components/Thankyou"));
const X = lazy(() => import("./components/X"));

const Error = lazy(() => import("./components/Error"));

function Routes() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="appie-visible active">
      <Router>
        <Suspense
          fallback={
            <div className="appie-loader active">
              <Bars
                wrapperClassName=""
                height="80"
                width="80"
                color="#90d3cb"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible
              />
            </div>
          }
        >
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/residential`}
              component={Residential}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/commercial`}
              component={Commercial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/confirmation`}
              component={Thankyou}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/broker-assistant`}
              component={BrokerAssistant}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/z`} component={X} />
            <Route exact path="/error" component={Error} />
            <Route component={Error} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default Routes;
